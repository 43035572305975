.background_img {
  background: var(--global-body-lightcolor) url("../assets/desktop-wave.svg")
    top right no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.how-it-works {
  .work-card {
    border-radius: var(--border-radius-10);
    box-shadow: none;
    transition: all 0.5s ease;

    &:hover {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    img {
      max-height: 157px;
      max-width: max-content;
      margin: 0 auto;
    }
  }
}

.core-features {
  background-color: var(--global-body-lightcolor);
  .features_wrapper {
    .features {
      background-color: var(--color-theme-primary);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.01);
      border-radius: var(--border-radius-10);
      transition: all 0.4s ease;

      svg {
        fill: #ffffff;
        margin: 0 auto;
      }

      h4.MuiTypography-root {
        color: #ffffff;
      }

      &:hover {
        background-color: var(--color-theme-primary);

        svg {
          fill: #ffffff;
        }

        h4.MuiTypography-root {
          color: #ffffff;
        }
      }
    }
  }
}

.journey {
  position: relative;

  .image-wrapper {
    background-color: var(--color-theme-secondary);
    padding: 24px 24px 24px 24px;
    border-radius: 20px 20px 0 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50px;
    left: 0;
    width: 250px;
    height: 250px;
    background: radial-gradient(
      circle,
      rgb(255 115 100 / 52%) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    filter: blur(100px);
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 50px;
    right: 0;
    width: 250px;
    height: 250px;
    background: radial-gradient(
      circle,
      rgb(255 115 100 / 52%) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    filter: blur(100px);
  }
}

@media only screen and (max-width: 1199.98px) {
  .background_img {
    padding: 10% 0;
  }
}

@media only screen and (max-width: 991.98px) {
  .journey {
    .reverse-flex {
      .MuiGrid-item {
        &:first-of-type {
          order: 2;
        }
        &:last-of-type {
          order: 1;
        }
      }
    }
  }
}
