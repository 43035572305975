.settingCard {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%) !important;
  border-radius: 10px;
  padding: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  // height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-menu {
  .MuiPopover-paper {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  }

  li {
    padding: 0 6px 0px 6px;
  }
}

.actionIcon {
  margin-left: 5px;
  margin-right: 5px;
}

.switch-case {
  $thumb-width: 24;
  $thumb-height: 24;
  $track-border-radius: 26 / 2;

  .root {
    width: 56;
    height: 26;
    padding: 0;
  }

  .switchBase {
    padding: 1;

    &.checked {
      transform: translateX(30px);
      color: var(--color-theme-primary) !important;

      & + .track {
        background-color: var(--color-theme-primary);
        opacity: 1;
        border: none;
      }
    }
  }

  .thumb {
    width: $thumb-width;
    height: $thumb-height;
  }

  .track {
    border-radius: $track-border-radius;
    background-color: var(--color-theme-primary);
    opacity: 1;
  }

  .checked {
  }

  .switchRoot {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    width: 0;
  }

  .label {
    font-size: 12;
    position: absolute;
    z-index: 1;
    color: var(--color-theme-primary) !important;
    user-select: none;
    pointer-events: none;
  }

  .left {
    left: 6;
  }

  .right {
    left: 27;
  }
}

.basic-multi-select-status {
  width: 160px !important;

  &:focus {
    border: none;
  }

  &:focus-visible {
    border: none;
  }

  &:active {
    border: none;
  }
}

.custom-avatar {
  /* CSS File or Global Styles */
  width: fit-content;
  .MuiAvatar-root {
    background-color: var(--color-theme-items);
    color: #fff; /* White text color */
    width: 30px;
    height: 30px;
    font-size: 1rem; /* Adjust text size */
  }
}

.settings-btn {
  .primary-button,
  // .primary-button:hover,
  .primary-button:focus,
  .primary-button:visited,
  .primary-button:active,
  .primary-button:active:hover,
  .primary-button:active:focus {
    background-color: var(--color-theme-primary-btn) !important;
    box-shadow: var(--shadow-none) !important;
    border-radius: var(--border-radius) !important;
    font-weight: var(--font-weight-semi-bold) !important;
    color: var(--color-theme-white) !important;
  }

  .primary-button:hover {
    background-color: var(--color-theme-primary-btn-hover) !important;
    color: var(--color-theme-white) !important;
    border-radius: var(--border-radius) !important;

    font-weight: var(--font-weight-semi-bold) !important;
  }
  // .primary-button:active:focus {
  //   background-color: var(--color-theme-secondary) !important;
  //   box-shadow: var(--shadow-none) !important;
  //   border-radius: var(--border-radius) !important;
  //   font-weight: var(--font-weight-semi-bold) !important;
  //   color: var(--color-theme-white);
  // }

  // .primary-button:hover {
  //   background-color: var(--color-theme-white) !important;
  //   color: var(--color-theme-secondary) !important;
  //   border-radius: var(--border-radius) !important;
  //   border: 1px solid var(--color-theme-secondary);
  //   font-weight: var(--font-weight-semi-bold) !important;
  // }

  .secondary-button,
  // .secondary-button:hover,
  .secondary-button:focus,
  .secondary-button:visited,
  .secondary-button:active,
  .secondary-button:active:hover,
  .secondary-button:active:focus {
    background-color: var(--color-theme-white) !important;
    box-shadow: var(--shadow-none) !important;
    border-radius: var(--border-radius) !important;
    color: var(--color-theme-secondary) !important;
    border-color: var(--color-theme-secondary) !important;
    font-weight: var(--font-weight-semi-bold) !important;
  }

  .secondary-button:hover {
    background-color: var(--color-theme-secondary) !important;
    color: var(--color-theme-white) !important;
    border-radius: var(--border-radius) !important;
    border-color: var(--color-theme-white) !important;
    font-weight: var(--font-weight-semi-bold) !important;
  }
}
