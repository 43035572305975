/**
 *
 * CSS variables
 *
 **/

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /* Typography */
  --global-font-family: "poppins";

  /* Fontsize */
  --font-size-small: 0.75em;
  --font-size-normal: 0.875em;
  --font-size-body: 1em;
  --font-size-h1: 4.209em;
  --font-size-h2: 3.75em;
  --font-size-h3: 2.369em;
  --font-size-h4: 1.777em;
  --font-size-h5: 1.333em;
  --font-size-h6: 1em;
  --font-size-xs: 0.75em;

  /* Line-Height */
  --font-line-height-body: 1.75;
  --font-line-height-p: 1.75;
  --font-line-height-h1: 1.3;
  --font-line-height-h2: 1.3;
  --font-line-height-h3: 1.3;
  --font-line-height-h4: 1.3;
  --font-line-height-h5: 1.3;
  --font-line-height-h6: 1.3;

  /* Font Weight*/
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;

  /* Letter spacing */
  --font-letter-spacing-body: 0;
  --font-letter-spacing-link: 0;
  --font-letter-spacing-h1: 0;
  --font-letter-spacing-h2: 0;
  --font-letter-spacing-h3: 0;
  --font-letter-spacing-h4: 0;
  --font-letter-spacing-h5: 0;
  --font-letter-spacing-h6: 0;
  --letter-spacing: 0.25em;
  --letter-spacing-one: 0.063em;
  --letter-spacing-one-half: 0.094em;
  --letter-spacing-two: 0.125em;
  --letter-spacing-three: 0.188em;
  --letter-spacing-four: 0.25em;

  /* Border Radius */
  --border-radius-zero: 0px;
  --border-radius: 4px;
  --border-radius-box: 8px;
  --border-radius-10: 10px;
  --border-radius-50: 50px;
  --border-radius-90: 90px;
  --border-radius-full: 50%;

  /* z index */
  --z-index-minus: -1;
  --z-index-zero: 0;
  --z-index-high: 99999;

  /* shadows */
  --shadow-none: none;

  /* Colors */
  --global-body-bgcolor: #ffffff;
  --global-body-lightcolor: #f6f6f6;
  --global-font-color: #273167;
  --global-font-title: #00446b;
  --global-font-subtitle: #000000;
  --font-secondary-color: #171717;

  --border-color-light: #ff7364;
  --border-color-dark: #262f48;
  --border-color-light-opacity: rgba(255, 255, 255, 0.2);
  --border-color-back: #e5e5e5;
  --border-color-dark-opacity: rgba(82, 95, 129, 0.1);

  --color-link: #ff7364;
  --color-link-active: #ff7364;
  --color-post-sticky-bg: #f2f4ff;
  --color-input-placeholder: #7a7a7a;
  --color-meta-bg: #e3e8ff;
  --color-meta-dot: #cdcdd6;

  /* Custom editor colors */
  --color-theme-primary: rgba(203, 146, 131, 1);
  --color-theme-primary-dark: #ff73640a;
  --color-theme-primary-light: #ff736416;
  --color-theme-secondary: #036185;
  --color-theme-secondary-gradient: linear-gradient(
    126deg,
    rgba(203, 146, 131, 1) 0%,
    rgba(95, 140, 158, 1) 100%
  );
  --color-theme-secondary-light: rgba(39, 49, 103, 0.1);
  --color-theme-black: #000;
  --color-theme-white: #ffffff;
  --color-theme-white-box: #ffffff;
  --color-theme-background: #d4d4d4;

  --color-theme-header: #00446b;
  --color-theme-items: #64bcd2;
  --color-theme-primary-btn: #ff8200;
  --color-theme-primary-btn-hover: #e67300;

  /* Opacity */
  --alpha-2: 0.2;
}

@media (min-width: 1920px) {
  :root {
    --font-size-body: 0.83vw;
  }
}

@media (max-width: 991px) {
  :root {
    --font-size-h1: 2.653em;
    --font-size-h2: 2.1em;
    --font-size-h3: 1.3em;
    --font-size-h4: 1.1em;
  }
}
