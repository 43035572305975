.table-wrapper {
  position: relative;
  border-radius: 5px;
}

.table-container {
  overflow-y: auto;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 2;
}

.loader-spinner {
  color: var(--color-theme-header) !important;
}

.table-head {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-row {
  display: flex;
}

.header-cell {
  display: flex;
  align-items: center;
  background-color: var(--color-theme-header) !important;
  color: #ffffff !important;

  cursor: pointer;
}

// .header-cell.sortable {
//   cursor: pointer;
// }

.sticky-cell {
  position: sticky;
  right: 0;
  background: #fff;
  z-index: 2;
  box-shadow: inset 3px 0 3px -3px rgba(0, 0, 0, 0.2);
}

.skeleton-row {
  display: flex;
}

.skeleton-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.data-row {
  display: flex;
  &:hover {
    background-color: rgb(255 115 100 / 5%); // Light gray background on hover
  }
}

.data-cell {
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease; // Smooth transition for hover effect
  color: var(--font-secondary-color) !important;
}

.no-data-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  gap: 4px;
}

.no-data-icon {
  color: rgba(0, 0, 0, 0.5);
  width: 48px !important;
  height: 48px !important;
}

.no-data-text {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px;
  margin-top: 8px;
}

.rows-per-page {
  display: flex;
  align-items: center;
}

.rows-per-page-text {
  margin-right: 1rem !important;
  font-size: 16px !important;
  color: var(--font-secondary-color) !important;
}

.rows-per-page-select {
  height: 35px !important;
  border-radius: 8px !important;
  color: var(--font-secondary-color) !important;
}
