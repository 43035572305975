.chatbot {
  h3.MuiTypography-root {
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-bold);
    color: var(--global-font-title);
  }

  .profile_component {
    border-radius: 10px;

    .chatbot-detail-logo-avatar-edit {
      position: absolute !important;
      bottom: 0px !important;
      right: 35px !important;
      padding: 0 !important;
      background: var(--global-body-bgcolor) !important;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .colorPicker {
    .MuiInputBase-root {
      height: 100% !important;
      border-radius: 8px !important;
    }

    input {
      padding: 0 !important;
      height: 100% !important;
      border-radius: 8px !important;
    }
  }

  .color-name-field {
    height: 20px;
    width: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 100px;
    input {
      bottom: unset;
      padding: 0;
      position: initial;
      right: unset;
      left: 0;
      text-align: center;
      // / width: 100px; /
      z-index: 999;
      // / transform: translateX(-50%); /
    }

    fieldset {
      top: 19px;
      border: none;
    }
  }

  .upload-button,
  // .upload-button:hover,
  .upload-button:focus,
  .upload-button:visited,
  .upload-button:active,
  .upload-button:active:hover,
  .upload-button:active:focus {
    background-color: transparent !important;
    box-shadow: var(--shadow-none) !important;
    border-radius: var(--border-radius-50) !important;
    color: var(--color-theme-white) !important;
    border: 1px solid var(--color-theme-secondary) !important;
    padding: 5px 25px;
    cursor: pointer;
    text-transform: capitalize;
  }

  .table-header {
    background-color: var(--color-theme-secondary) !important;
    color: var(--global-body-bgcolor);
    border: solid 1px;
    border-radius: 5px;
    padding: 10px;
  }
}

.chatbot-logo {
  background: transparent;

  .chat-avatar_wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    box-shadow: 0 0 20px 4px rgb(39 49 103 / 44%);
    border: none;
    border-radius: 50%;
    padding: 0;
    font-size: 18px;
    background: transparent;

    .avatar-wrapper {
      display: flex;
      background-color: #ffffff;
      padding: 20px;
      border-radius: 60px;
      width: 100px;
      height: 100px;
      position: relative;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;

      &:before {
        content: "";
        background-color: #ffffff;
        border-radius: 50%;
        display: block;
        position: absolute;
        z-index: -1;
        width: 44px;
        height: 44px;
        top: -12px;
        left: 28px;
        box-shadow: -50px 30px 0 -12px #ffffff;
      }

      &:after {
        content: "";
        background-color: #ffffff;
        border-radius: 50%;
        display: block;
        position: absolute;
        z-index: -1;
        bottom: -10px;
        right: 26px;
        width: 30px;
        height: 30px;
        box-shadow: 40px -34px 0 0 #ffffff, -28px -6px 0 -2px #ffffff,
          -24px 17px 0 -6px #ffffff, -5px 25px 0 -10px #ffffff;
      }

      .MuiAvatar-root {
        &.MuiAvatar-circular {
          border-radius: 0;
          width: 100%;
          height: 100%;

          img {
            max-width: 60px;
          }
        }
      }
    }
  }

  .chatPreview_wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    border: none;
    padding: 0;
    font-size: 18px;
    background: transparent;
    box-shadow: 0 0 15px 10px rgb(0 0 0 / 14%);
    border-radius: 10px;
    z-index: 999;

    form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;

      button {
        background: #001275;
        border-radius: 0 8px 8px 0;
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}

.chatbot-header {
  display: flex;
  justify-content: space-between;
}

.chatbot-welcome {
  font-size: 18px;
  font-weight: bold;
  display: flex;
}

.chatbot-message-history {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  margin-bottom: 15px;
  border-right: 1px solid #ccc;
  background-color: #ced0d382;
  height: 70%;
}

.chatbot-message {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 50%;
}

.chatbot-message-system {
  align-self: flex-start;
}

.chatbot-message-assistant {
  align-self: flex-end;
}

.chatbot-message-text {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.chatbot-message-system .chatbot-message-text {
  background-color: #f5f5f5;
}

.chatbot-message-assistant .chatbot-message-text {
  background-color: #dcf8c6;
}

.chatbot-message-user {
  align-self: flex-end;
}

.chatbot-message-user .chatbot-message-text {
  background-color: #dcf8c6;
}

.chatbot-message-metadata {
  font-size: 12px;
}

.first-grid-chat-config-paper {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: block;
}

.chatbot-detail-logo-section {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
  justify-content: center !important;
}

.chatbot-detail-logo-loader {
  width: 150px !important;
  height: 150px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.chatbot-deatil-logo {
  position: relative !important;
  width: 150px !important;
  height: 94px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.chatbot-detail-logo-avatar {
  width: 94px !important;
  height: 94px !important;
  border-radius: 50% !important;
  border: 4px solid #036185 !important; // Adjust border properties as needed
}

.color-schema-section {
  padding: 1rem !important;
  border-radius: 10px !important;
}

.color-box {
  height: 50px !important;
  padding: 0 !important;
  border-radius: 8px !important;
}

.fileUrl-section {
  padding: 1rem !important;
  width: 100% !important;
  height: 100% !important;
  margin-right: 1rem !important;
  border-radius: 10px !important;
}

.fileUrl-listing {
  max-height: 320px !important;
  overflow-y: auto;
  padding-top: 1rem !important;

  a {
    color: #000000 !important;
  }

  .icon-wrap {
    svg {
      stroke: #ff0000;
    }
  }
}

.more_colors {
  border-radius: 20px;
  cursor: pointer;
  display: block;
  height: 84px;
  margin: 0;
  position: relative;
  width: 100%;
}

.more_colors input {
  bottom: 0;
}

.colorPicker {
  // width: 250px;
  height: 84px !important;
}

.color-box-white {
  input {
    color: #fff;
  }
}
.color-box-black {
  input {
    color: #000;
  }
}

@media only screen and (max-width: 600px) {
  .media-padding {
    padding-left: 0px !important;
  }

  .fileUrl-section {
    .MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: baseline !important;
      gap: 10px;

      .MuiBox-root {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
  }
}
