.pricing-card-price {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  color: #0d333f !important;
}

.currunt-plan-media {
  flex-direction: row !important;
  align-items: center;
}

.pricing-card-svg {
  svg {
    min-width: 27px;

    @media screen and (max-width: 500px) {
      min-width: 20px;
      min-height: 20px !important;
    }
  }
}

// .no-arrow-pricing-card {
//   &:hover {
//     // background-color: #fff !important;
//     color: red !important;
//   }
// }

.active_status {
  background-color: green;
}
.inactive_status {
  background-color: red;
}

.selected-plan-billing {
  cursor: not-allowed !important;
  border: 2px solid #036185;
  color: #fff;
  opacity: 0.7;
}

.selected-plan-billing-subscription {
  border: 2px solid #036185;
}

.pricing-card-price-transaction {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  color: #0d333f !important;
}

@media only screen and (max-width: 600px) {
  .cancel-sub {
    padding: 10px !important;
    font-size: 14px !important;
  }

  .active-font {
    font-size: 14px;
    padding: 5px 7px;

    span {
      height: 12px !important;
      width: 12px !important;
    }
  }

  .pricing-card-price-transaction {
    font-size: 16px !important;
  }

  .media-font-h3 {
    font-size: 18px !important;
  }

  .currunt-plan-media {
    display: flex !important;
    align-items: baseline !important;
    flex-direction: column !important;
  }

  .margin-0 {
    margin: 0 !important;
  }

  .payment-column {
    flex-direction: column !important;
    align-items: baseline !important;
  }
}

@media only screen and (max-width: 430px) {
  .change-plan-media {
    padding: 10px 5px !important;
  }
}
