.breadcrumb {
  margin-bottom: 10px;
}

.folder,
.file {
  display: flex;
  align-items: center;
  margin: 5px;
  margin-left: 1px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.folder:hover,
.file:hover {
  background-color: #f0f0f0;
}

.folder img,
.file img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.checkbox {
  margin-right: 5px;
}

.modal-save-button {
  .primary-button,
  .primary-button:hover,
  .primary-button:focus,
  .primary-button:visited,
  .primary-button:active,
  .primary-button:active:hover,
  .primary-button:active:focus {
    background-color: var(--color-theme-secondary) !important;
    box-shadow: var(--shadow-none) !important;
    border-radius: var(--border-radius) !important;
    font-weight: var(--font-weight-semi-bold) !important;
  }
}

.modal-save-button {
  display: flex;
  justify-content: flex-end;
}
