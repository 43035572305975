.action-background {
  background-image: url("../../src/assets/runWrokflow/background-workflow.png");
  border: "1px solid #D8D7D7";
  border-radius: 12px;
  padding: 20px;
  display: "flex";
  justify-content: "center";
  flex-direction: "column";
  height: 100%;
}

.edit-wf-close-btn {
  svg {
    height: 20px;
    width: 20px;
  }
}

.edit-workflow-btn {
  &:hover {
    border: 1px solid #64bcd2;
    background-color: #fff;
    color: #64bcd2;
  }
}

.horizontal-hr {
  margin: 0;
  width: 100%;
  height: 5px !important;
}

.vertical-hr {
  transform: rotate(90deg);
  width: 5px;
  height: 100% !important;
}

.all-actions-modal {
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  background: #f2f2f2;
  padding: 8px 16px;
  text-align: center;
  margin: 10px;
  text-transform: capitalize;
  cursor: pointer;
}
.run-history {
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  background: #f2f2f2;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  cursor: pointer;
}

.before-vertical {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 5px;
    width: 5px;
    left: -2.5px;
    background: #c1c1c1;
  }
}

.before-vertical-media {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 40px;
    width: 5px;
    left: -2.5px;
    top: -15px;
    background: #c1c1c1;
  }
}
.after-vertical-media {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    height: 16px;
    width: 5px;
    left: -2.5px;

    background: #c1c1c1;
  }
}

.Workflow-Template-text {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #777777;
  line-height: 27px !important;
  //   margin-top: 10px !important;
}

.Workflow-Template-subtitle-text {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #777777;
  line-height: 14px !important;
}

.MuiFormControl-root {
  width: 100%;
  .manual-trigger-details {
    .MuiFormControlLabel-root {
      display: flex;
      //   .MuiBox-root {
      .MuiTypography-root {
        width: 100%;
      }
      //   }
    }
  }
}

.manual-trigger {
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  background: #f2f2f2;
  //   padding: 8px 16px;
  //   display: flex;
  //   justify-content: space-between;
  margin: 10px;
  width: 100%;

  .manual-input-base {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #c1c1c1;
    width: 100%;
    margin: 10px 0;

    input {
      padding: 8px 16px;
    }
  }
}

.workflow-finished-btn {
  background: #036185 !important;
  color: #fff !important;
  padding: 8px;
  font-size: 12px !important;
  font-weight: 500;
  height: 28px;
}

.select-remain-data {
  border: 1px solid red !important;
}

.workflow-history-paper {
  border: 1px solid #d8d7d7;
}

.accordian-workflow-history {
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
}

.action-cards {
  min-width: 100%;
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 12px !important;
  padding: 15px;
  margin: 15px 0;

  .card-action-title {
    color: #00446b;
    font-size: 18px;
    font-weight: 500;
  }
  .card-action-subtitle {
    color: #959595;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .actions-stack {
    background: #ece8e8;
    padding: 15px;
    border-radius: 8px;
  }
}

.selected-action {
  color: #959595;
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-bottom: 10px !important;
}

.add-action-button {
  background-color: "#036185";
  padding: "8px 16px 8px 16px";
  border-radius: "8px !important";
}
