.skeleton-list {
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  padding: 0;
}
.section-title {
  margin-top: 30px;
}

.card-link {
  color: inherit;
  text-decoration: none;
}
.card-image {
  width: 100%;
}
.channel-image {
  border-radius: 100%;
  padding: 0, 10px, 0, 0;
  width: 40px;
  height: 40px;
}
.card-title {
  margin-top: 10px;
  margin-bottom: 0;
}
.card-channel {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}
/* Tablets */
@media (max-width: 1000px) {
  .card {
    width: calc(50% - 22px);
  }
}
/* Mobiles \*/
@media (max-width: 640px) {
  .card {
    width: 100%;
  }
}

.card-skeleton {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: lightgray;
  margin: 10px 0;
  border-radius: 5px;
}

.card-skeleton.right {
  width: 100%;
  padding: 10px 70px;
}

.card-skeleton.left {
  width: 100%;
  padding: 10px;
}
.sleleton-chat.right {
  justify-content: flex-end;
  text-align: end;
  width: 150px;
}

.sleleton-chat.left {
  justify-content: flex-start;
  text-align: start;
  width: 500px;
}
