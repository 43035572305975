.footer_wrapper {
  background-color: var(--color-theme-secondary-light);
  border-radius: 30px;

  .nav-items button,
  .nav-items button:hover,
  .nav-items button:focus,
  .nav-items button:visited,
  .nav-items button:active,
  .nav-items button:active:horizontal,
  .nav-items button:active:focus {
    background-color: transparent !important;
    color: var(--global-font-color);
    font-weight: var(--font-weight-semi-bold);
    font-size: var(--font-size-normal);
    line-height: var(--font-line-height-p);
    letter-spacing: var(--letter-spacing-one);
  }
}
.description {
  color: var(--color-theme-secondary);
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-top: 0px !important;
  padding-top: 5px !important;
}
.title {
  color: #425262 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding-left: 10px !important;
}

.social-wrapper {
  a {
    svg {
      fill: var(--color-theme-secondary);
      transition: all 0.4s ease;
    }

    &:hover {
      svg {
        fill: var(--color-link);
      }
    }
  }
}

.MuiPaper-root {
  &.subscribe {
    width: auto;
    box-shadow: var(--shadow-none);
    border-radius: var(--border-radius-50);

    .MuiInputBase-root {
      input {
        padding: 0;
      }
    }

    .send-button {
      background-color: #64bcd2;
      color: #ffffff;
      width: 100%;
    }
  }
}

.copyright-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--border-color-dark-opacity);

  .MuiTypography-caption {
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-medium);
    a {
      color: var(--color-link);
      font-weight: var(--font-weight-bold);
    }
  }
}

.contact-info {
  background-color: var(--color-theme-primary);
  button.MuiButtonBase-root {
    background-color: #ffffff;
    width: 64px;
    height: 64px;
    svg {
      fill: var(--color-theme-primary);
    }
  }
}

@media only screen and (max-width: 600px) {
  .copyright-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
