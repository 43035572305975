/* Full-width sidebar and form components */
.builder-sidebar {
  width: 100% !important;
  background-color: #ffffff !important;
  border-right: none !important;
}

/* Customize form field styles */
.form-group {
  width: 100%;
  margin-bottom: 15px;
}

/* Change color for field labels and borders */
.formio-component {
  border-radius: 6px;

  margin-bottom: 20px;
  background-color: #fff;
}

.formio-component .control-label {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

/* Customize button styles */
.btn-primary {
  background-color: #036185 !important;
  border: none;
  font-size: 16px !important;
  padding: 10px !important;
}

/* Form preview wrapper */
.form-builder-container {
  max-width: 100%;
  background-color: #f1f1f1;
  padding: 30px;
  border-radius: 8px;
}
.card-body {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.formbuilder {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.formcomponents {
  width: 100% !important;
}
.formarea {
  width: 100% !important;
}
.border-red {
  border: 2px solid red;
}

/* AccordionStyles.css */

.accordion-summary-root {
  height: 56px !important;
}

.accordion-summary-root.Mui-expanded {
  min-height: 56px !important; /* Apply same minHeight when expanded */
}

.accordion-summary-typography {
  font-size: 16px !important;
  color: white !important;
  font-weight: 400 !important;
}

.accordion-summary-background {
  background-color: var(--color-theme-header) !important;
  color: #ffffff !important;
}

.accordion-summary-content {
  margin: 0 !important;
}

.accordion-details {
  padding: 16px !important;
}

.expand-icon {
  color: #ffffff !important;
}

.templates-list {
  height: calc(100vh - 175px);
  overflow-y: auto;
}

/* Media query for small screen sizes */
@media (max-width: 600px) {
  /* Adjust the max-width as needed */
  .templates-list {
    /* Default styles for larger screens */
    height: auto; /* Or other styles for larger screens */
    overflow-y: visible;
  }
}

.signatureCanvas {
  border: 1px solid rgb(206, 212, 218);
  width: 100%;
  height: 200px;
}
