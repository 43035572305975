// Navbar Styles //
.dashboard-navbar {
  .small-logo {
    display: none;
  }

  &.sidebar-closed {
    div {
      .navbar-left {
        gap: 0;
        min-width: 80px;

        .small-logo {
          display: block;
          width: 33px;
          position: relative;
          left: 10px;
        }

        .large-logo {
          display: none;
        }

        .toggle-btn {
          background-color: var(--primary-text-color);
          position: relative;
          left: 25px;

          .open-menu {
            display: none;
          }

          .close-menu {
            display: block;
          }
        }
      }

      .navbar-right {
        width: 100%;
      }
    }
  }

  .avatar-ui {
    border-radius: 10px;
  }

  & > div {
    padding: 0;

    .navbar-left {
      background-color: var(--primary-text-color);
      border-bottom: 1px solid var(--primary-text-color) !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 50px;
      min-width: 260px;
      padding: 14px 8px;
      box-sizing: border-box;
      transition: all 0.5s;
      -webkit-transition: all 0.25s;

      img {
        width: 144px;
        position: relative;
        left: 10px;
      }

      svg {
        fill: var(--color-theme-white);
      }

      .toggle-btn {
        .close-menu {
          display: none;
        }
      }
    }

    .navbar-right {
      padding: 0 24px 0 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .user-info {
        display: flex;
        align-items: center;
        gap: 2px;
        font-size: 16px;
        font-weight: 500;

        .MuiAvatar-root {
          width: 34px;
          height: 34px;
          background-color: #e3e3e3;
          border-radius: 34px;
        }

        button {
          text-transform: capitalize;
          color: var(--global-font-title);
          font-size: 16px;
          font-weight: 500;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

// Sidebar Styles //
.sidebar-wrapper {
  position: relative;
  z-index: 1;

  &.sidebar-menu-closed {
    .MuiDrawer-paper {
      transform: none !important;
      visibility: unset !important;
      display: none;

      .list-wrap {
        width: 80px;

        ul {
          &.sidebar {
            li {
              .MuiListItemButton-root {
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
  .left-logo {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: transparent !important;
    min-height: 100px;
    height: 100px;

    z-index: 10; // Ensure this box stays on top
    backdrop-filter: blur(200px);
  }
  .large-logo {
    margin: 0px auto;
    cursor: pointer;
    transition: max-width 0.5s, margin 0.5s;
  }
  .MuiDrawer-paper {
    transition: all 0.5s !important;
    -webkit-transition: all 0.25s !important;
    border-right: none;
    transform: none !important;
    visibility: unset !important;
    background: linear-gradient(
      to bottom,
      #f0f8fb,
      #d1ebf2,
      #b2dee9
    ) !important;
    box-shadow: 0 10px 20px rgba(135, 206, 235, 0.2);

    //   background:
    //   linear-gradient(to bottom,#b2e0f7,#68b6c4,#2a6f85   ) !important;

    // color: #fff;
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #273167;
    }
  }

  .small-width-sidebar-grid {
    grid-template-columns: 1fr !important;
  }

  .list-wrap {
    height: 100vh;
    background-color: var(--primary-text-color);

    padding-top: 4px !important;
    padding-bottom: 12px;
    margin-top: 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s;
    -webkit-transition: all 0.25s;
    transition: width 0.5s ease;
    width: 240px; /*jk*/

    .sidebar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      cursor: pointer;

      .item-box {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        padding: 12px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
        margin-bottom: 8px;
        color: black;
      }
      .active {
        color: white;
        background-color: var(--color-theme-secondary);
      }
      // .item-box:hover {
      //   background-color: #f0f0f0; /* Color change on hover */
      //   cursor: pointer; /* Change cursor to indicate it's clickable */
      // }
    }

    ul.sidebar {
      display: flex;
      flex-direction: column;
      gap: 4px;
      // display: grid; /*jk*/
      // /* padding: 4px; */

      // grid-template-columns: 1fr 1fr; /*jk*/

      // transition: grid-template-columns 2s ease, gap 0.5s ease;
      // gap: 6px; /*jk*/

      @media (max-width: 601px) {
        grid-template-columns: 1fr;
      }

      li {
        padding: 5px 8px;

        .MuiListItemButton-root {
          border-radius: 8px;
          align-items: center;
          gap: 12px;
          padding: 10px 12px;
          flex-direction: column;

          .MuiListItemText-root {
            margin: 0;
          }
        }

        .icon-wrap {
          min-width: auto !important;

          svg {
            fill: var(--textgrey);
            font-size: 25px;
          }
        }

        span {
          color: var(--textgrey);
          text-align: center;
          font-size: 15px;
          line-height: 18px;
        }

        &:hover {
          .MuiListItemButton-root {
            background-color: var(--color-theme-secondary);

            .icon-wrap {
              svg {
                fill: var(--color-theme-white) !important;
              }
            }

            .icon-wrap {
              svg g rect {
                fill: var(--color-theme-white) !important;
              }
            }

            span {
              color: var(--color-theme-white);
            }
          }
        }

        &.active {
          .MuiListItemButton-root {
            background-color: var(--color-theme-secondary);

            svg {
              fill: var(--color-theme-white);
            }

            svg g rect {
              fill: var(--color-theme-white) !important;
            }

            span {
              color: var(--color-theme-white);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .navbar-right {
    padding: 0 14px !important;
  }

  .sidebar-wrapper {
    position: relative;
    z-index: 99;

    .list-wrap {
      width: 100%;

      .MuiListItemButton-root {
        flex-direction: row !important;

        .MuiListItemText-root {
          margin: 0;
          flex: none !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .menu-bar {
    margin: 0 !important;
  }
  .curruntPageLink {
    font-size: 16px !important;
    margin-right: 15px !important;
  }

  .breadcrumbsLink {
    font-size: 18px !important;
  }
  .header-title {
    font-size: 18px !important;
  }
}

// Sidebar styles END //

.main-content {
  padding-bottom: 0;

  overflow-x: hidden;
  // padding: 24px 12px 0px;
  // margin-bottom: 0px;
  // min-height: 100vh;
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
  margin-left: 240px; /*jk*/
  transition: margin-left 0.5s ease;

  &.full-width {
    margin-left: 240px; /*jk*/
  }
  @media only screen and (max-width: 600px) {
    &.full-width {
      margin-left: 0px !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .main-content {
    margin-left: 0 !important;
  }
}

.content-wrapper {
  margin: 24px 24px;
  background-color: transparent !important;
  height: calc(100vh - 120px);

  .content-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    h2.MuiTypography-root {
      font-size: var(--font-size-h4);
      font-weight: var(--font-weight-bold);
      color: var(--global-font-title);
    }

    .content-header-right {
      align-items: center;
      display: flex;
      // flex-wrap: wrap;
      gap: 16px;
    }

    .content-header-right-chat {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}

.configureAction {
  .MuiDrawer-paper {
    width: 75%;

    .sidebar-wrapper {
      background-color: var(--color-theme-secondary);
    }
  }
}

.side-drawer {
  .MuiDrawer-paper {
    width: 50%;

    @media only screen and (max-width: 600px) {
      width: 70%;
    }

    .sidebar-wrapper {
      background-color: var(--color-theme-secondary);
    }
  }
}

.form-wrap {
  flex: 1;
  border-radius: 10px;
  max-height: calc(100vh - 110px);

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      .react-datepicker__calendar-icon {
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    input {
      width: 100%;
      border-style: solid;
      border-width: 1px;
      overflow: hidden;
      border-color: rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      padding: 8px 10px;
    }
  }

  .MuiToggleButtonGroup-root {
    .MuiToggleButton-root {
      border: 1px solid rgba(0, 0, 0, 0.23);
      text-transform: none;
      font-size: var(--font-size-h6);
      padding: 6px 24px;

      &:first-of-type {
        border-radius: 50px 0 0 50px;
      }

      &:last-of-type {
        border-radius: 0 50px 50px 0;
      }

      &.Mui-selected {
        background-color: var(--color-theme-secondary);
        color: #ffffff;
      }
    }
  }
}

.modal-footers::-webkit-scrollbar {
  width: 4px;
}

.modal-footers::-webkit-scrollbar-thumb {
  background: #cccccc;
}

.modal-footers {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  align-items: center;
  flex-direction: column;

  .primary-button,
  // .primary-button:hover,
  .primary-button:focus,
  .primary-button:visited,
  .primary-button:active,
  .primary-button:active:hover,
  .primary-button:active:focus {
    background-color: var(--color-theme-primary-btn) !important;
    box-shadow: var(--shadow-none) !important;
    border-radius: var(--border-radius) !important;
    font-weight: var(--font-weight-semi-bold) !important;
    color: var(--color-theme-white) !important;
  }

  .primary-button:hover {
    background-color: var(--color-theme-primary-btn-hover) !important;
    color: var(--color-theme-white) !important;
    border-radius: var(--border-radius) !important;

    font-weight: var(--font-weight-semi-bold) !important;
  }
  // .primary-button:active:focus {
  //   background-color: var(--color-theme-secondary) !important;
  //   box-shadow: var(--shadow-none) !important;
  //   border-radius: var(--border-radius) !important;
  //   font-weight: var(--font-weight-semi-bold) !important;
  //   color: var(--color-theme-white);
  // }

  // .primary-button:hover {
  //   background-color: var(--color-theme-white) !important;
  //   color: var(--color-theme-secondary) !important;
  //   border-radius: var(--border-radius) !important;
  //   border: 1px solid var(--color-theme-secondary);
  //   font-weight: var(--font-weight-semi-bold) !important;
  // }

  .secondary-button,
  // .secondary-button:hover,
  .secondary-button:focus,
  .secondary-button:visited,
  .secondary-button:active,
  .secondary-button:active:hover,
  .secondary-button:active:focus {
    background-color: var(--color-theme-white) !important;
    box-shadow: var(--shadow-none) !important;
    border-radius: var(--border-radius) !important;
    color: var(--color-theme-secondary) !important;
    border-color: var(--color-theme-secondary) !important;
    font-weight: var(--font-weight-semi-bold) !important;
  }

  .secondary-button:hover {
    background-color: var(--color-theme-secondary) !important;
    color: var(--color-theme-white) !important;
    border-radius: var(--border-radius) !important;
    border-color: var(--color-theme-white) !important;
    font-weight: var(--font-weight-semi-bold) !important;
  }
}

.modal-footer {
  background-color: #ffffff;
  box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.08);

  .primary-button,
  .primary-button:hover,
  .primary-button:focus,
  .primary-button:visited,
  .primary-button:active,
  .primary-button:active:hover,
  .primary-button:active:focus {
    background-color: var(--color-theme-secondary) !important;
    box-shadow: var(--shadow-none) !important;
    border-radius: var(--border-radius) !important;
    font-weight: var(--font-weight-semi-bold) !important;
  }

  .secondary-button,
  .secondary-button:hover,
  .secondary-button:focus,
  .secondary-button:visited,
  .secondary-button:active,
  .secondary-button:active:hover,
  .secondary-button:active:focus {
    background-color: transparent !important;
    box-shadow: var(--shadow-none) !important;
    border-radius: var(--border-radius) !important;
    color: var(--color-theme-secondary) !important;
    border-color: var(--color-theme-secondary) !important;
  }
}

.primary-button-add,
.primary-button-add:hover,
.primary-button-add:focus,
.primary-button-add:visited,
.primary-button-add:active,
.primary-button-add:active:hover,
.primary-button-add:active:focus {
  background-color: var(--color-theme-secondary) !important;
  box-shadow: var(--shadow-none) !important;
  border-radius: var(--border-radius) !important;
  font-weight: var(--font-weight-semi-bold) !important;
}

.primary-button-add:disabled {
  cursor: not-allowed;
}

.document-item-wrapper {
  .document-media {
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .document-title {
    padding: 12px !important;

    .MuiTypography-root {
      margin-bottom: 0;
    }
  }
}

.selected-doc-card {
  background-color: "#273167";
  color: "white";
}

.citation-preview {
  cursor: pointer;
  color: #036185;
  font-weight: 700;
  font-size: 13px;
}

.MuiButtonBase-root {
  &.MuiIconButton-root {
    &.sendQ-btn {
      background-color: #036185;
      padding: 12px;
      border-radius: 0 8px 8px 0;
    }
  }
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

.secondary-button-theme,
.secondary-button-theme:hover,
.secondary-button-theme:focus,
.secondary-button-theme:visited,
.secondary-button-theme:active,
.secondary-button-theme:active:hover,
.secondary-button-theme:active:focus {
  background-color: transparent !important;
  box-shadow: var(--shadow-none) !important;
  border-radius: 20px !important;
  color: #273365 !important;
  border-color: #273365 !important;
}

.scrolling-area {
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f2f4ff;
    transition: all 0.4s ease;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #dadada;
    }
  }
}

.MuiInputBase-root {
  .MuiInputBase-input {
    &.Mui-disabled {
      color: #dadada;
      -webkit-text-fill-color: #726d6d;
      background-color: transparent !important;
    }

    &::placeholder {
      /* Chrome/Opera/Safari */
      color: #dadada;
      font-size: 16px;
      opacity: 1;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #dadada;
      font-size: 16px;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #dadada;
      font-size: 16px;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #dadada;
      font-size: 16px;
      opacity: 1;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #dadada;
      font-size: 16px;
      opacity: 1;
    }
  }
}

.select__placeholder {
  color: #dadada !important;
  font-size: 16px;
}

.no-data-found {
  height: calc(100vh - 200px);
  align-items: center;
  font-size: 24px;
  text-transform: capitalize;
  color: #dadada;
  font-weight: 600;
}

.no-files-found {
  font-size: 20px;
  text-transform: capitalize;
  color: #cecece;
  font-weight: 600;
}

.separator_line {
  background-color: #b3b3b3;
}

.MuiPagination-root {
  &.MuiPagination-outlined {
    .MuiPagination-ul {
      li {
        .MuiPaginationItem-previousNext {
          // background-color: transparent;
          // border: none;
          // padding: 0;
          border-radius: 8px;

          svg {
            font-size: 1.5rem;
            // fill: #273167;
          }
        }

        .MuiPaginationItem-page {
          &.Mui-selected {
            background-color: var(--color-theme-items);
            color: #ffffff;
            border-radius: 8px;
            // border-radius: 50%;
          }
        }
      }
    }
  }
}

.MuiDialog-container {
  .MuiPaper-root {
    border-radius: 10px;

    .dialog-title-wrapper {
      background-color: var(--color-theme-primary);
      color: #ffffff;

      h6 {
        padding: 8px 15px;
      }

      svg {
        fill: #ffffff;
      }
    }

    .dialoag-contents {
      padding: 30px;

      .uploded_files-wrapper {
        .icon-wrap {
          cursor: pointer;

          svg {
            stroke: #ff0000;
          }
        }
      }
    }

    .MuiDialogActions-root {
      padding: 8px 8px 20px 8px;
      border-top: 2px solid #ccc;
    }
  }
}

@media only screen and (max-width: 800px) {
  .message-item {
    max-width: 90% !important;
  }
}

@media only screen and (max-width: 600px) {
  .content-header-right {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    margin: auto;
    width: 100%;
    gap: 20px;

    .primary-button.add-btn {
      width: 100%;
      padding: 8px 12px;
      font-size: 14px;
    }
    .primary-button.filter-btn {
      width: 100%;
      padding: 8px 12px;
      font-size: 14px;
    }

    .MuiPaper-root {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .content-header-right {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    margin: auto;
    width: 100%;
    gap: 20px;
    align-items: flex-start !important;

    .primary-button.add-btn {
      padding: 8px 12px;
      font-size: 16px;
    }

    .MuiPaper-root {
      max-width: 100%;
      width: 100%;
    }
  }
  .side-drawer {
    .MuiDrawer-paper {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 480px) {
  .user-info {
    flex-direction: column;

    .MuiAvatar-root {
      width: 28px !important;
      height: 28px !important;
    }

    button {
      font-size: 14px !important;
      font-weight: 500;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .document-listing-wrapper {
    height: calc(100vh - 260px);
  }

  .side-drawer .MuiDrawer-paper {
    width: 80%;
  }

  .chat-box-container {
    height: calc(100vh - 310px) !important;
  }
}

.breadcrumbsLink {
  font-size: 20px;
  font-weight: 600;
  color: #00446b8c !important;
}
.header-title {
  font-size: 20px;
  font-weight: 600 !important;
  color: var(--color-theme-header) !important;
}

.curruntPageLink {
  color: #00446b;
}

.run-action-title {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
}

.run-workflow-save-button {
  max-width: fit-content;
  background: white !important;
}

.active-button {
  .Mui-selected {
    background-color: #036185 !important;
    color: white !important;

    &:hover {
      background-color: #036185;
    }
  }
}

.todolist-container::-webkit-scrollbar {
  height: 8px; /* Set the height of the horizontal scrollbar */
  width: 6px; /* Set the width for the vertical scrollbar */
}

/* Styling the scrollbar track */
.todolist-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray background for the track */
}

.todolist-container::-webkit-scrollbar-thumb {
  background-color: #64bcd2; /* Blue color for the scrollbar */
  border-radius: 10px; /* Rounder edges */
}

.highcharts-data-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.highcharts-data-table table {
  width: 100%;
}

.highcharts-data-table caption {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.highcharts-data-table th,
.highcharts-data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.highcharts-data-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.highcharts-data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.highcharts-data-table tr:hover {
  background-color: #f1f1f1;
}

.highcharts-data-table td {
  vertical-align: top;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-close-btn {
  background: red;
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

.my-dashboard {
  .todo-list-tabs {
    border-bottom: 1;
    border-color: divider;
    display: flex;
    align-items: center;
  }
  .todo-list-tabs-mobile {
    flex-direction: column;
    gap: 12px;
  }
}

.header-text {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.description-text {
  font-size: 14px !important;
}
.detailpage-header {
  font-size: 18px !important;
  color: var(--color-theme-secondary) !important;
  font-weight: 600 !important;
}
