.active-steps span {
  color: #a3a3a3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active-steps .MuiStepLabel-iconContainer {
  display: none !important;
}

.lable-step .step-lable-numbers {
  border-radius: 50%;
  height: 73px;
  width: 73px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lable-step .step-lable-numbers-inactive {
  border: 3px solid #a3a3a3;
}

.lable-step .step-lable-numbers-active {
  border: 3px solid #036185;
}

.step-lable-label-inactive {
  color: #a3a3a3 !important;
}

.step-lable-label-active {
  color: #036185 !important;
}

.disabled-button {
  opacity: 0.7;
}
