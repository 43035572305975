.main-div-assistant {
  border: 1px solid #0d333f54;
  max-width: 72rem;
  //   width: 600px;
  padding: 2.5rem;
  background-color: white;
  border-radius: 10px;

  .MuiStepper-root {
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }
}

.disable-btn-subscription-page {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.7 !important;
}

.margin-bottom-input {
  margin-bottom: 5px !important;
}

.step-circle > span > svg {
  color: var(--color-theme-primary) !important;
}

.active-step .MuiStepLabel-label {
  color: #fff !important;
  font-size: 16px !important;
}
.inactive-text .MuiStepLabel-label {
  color: #8a8a8a !important;
  font-weight: 400;
  font-size: 16px !important;
}

.step-lable .MuiStepLabel-iconContainer,
.MuiStepConnector-root {
  display: none !important;
}

.step-lable {
  border-radius: 400px;
  background-color: #f3f6f9;
  padding: 10px 60px !important;
  position: relative;
}

.disablestep {
  color: #fff !important;
  opacity: 0.7;
}

// .login-wrapper-subscription {
//   height: auto;
//   padding: 20px;
//   form {
//     box-shadow: none;
//   }
// }
.login-wrapper-subscription-choose-your-plan {
  height: auto;
  padding: 20px;
  background-color: var(--global-font-title);
  display: flex;
  align-items: center;
  form {
    box-shadow: none;
  }
}

.pricing-page-wrapper {
  .hero-section-wrapper {
    height: 100%;
    padding-top: 74px;
    padding-bottom: 8px;
    position: relative;
    .main-heading {
      font-family: Poppins;
      font-size: 44px;
      font-weight: 700;
      line-height: 52.8px;
      text-align: center;
      margin-bottom: 16px;
      background: radial-gradient(
          93.49% 128.28% at 87.57% 100%,
          #64bcd2 0%,
          #036185 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .sub-heading {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      margin-bottom: 32px;
      color: #000000;
    }
    .book-demo-btn {
      width: 182px;
      height: 52px;
      padding: 16px 25px 16px 25px;
      gap: 10px;
      border-radius: 12px;
      background: radial-gradient(
        100.93% 138.49% at -0.26% 7.52%,
        #64bcd2 0%,
        #036185 100%
      );
      border: none;
      text-transform: none;
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: center;
    }
    @media screen and (min-width: 961px) and (max-width: 1280px) {
      .main-heading {
        font-size: 35px;
        line-height: 48px;
      }
      .sub-heading {
        font-size: 18px;
        line-height: 26px;
      }
      .book-demo-btn {
        width: 182px;
        padding: 12px 18px 12px 18px;
      }
    }
    @media screen and (min-width: 601px) and (max-width: 960px) {
      .main-heading {
        font-size: 30px;
        line-height: 48px;
      }
      .sub-heading {
        font-size: 18px;
        line-height: 26px;
      }
      .book-demo-btn {
        width: 182px;
        padding: 12px 18px 12px 18px;
      }
      .HeroBanner {
        max-width: 400px;
        margin: auto;
      }
    }
  }
  @media screen and (max-width: 601px) {
    .main-heading {
      font-size: 30px;
      line-height: 48px;
    }
    .hero-section-wrapper {
      padding-top: 50px !important;
    }
    .pricing-card-subtitle {
      font-size: 14px !important;
    }
    .pricing-card-title {
      font-size: 18px;
    }
  }
}

.pricing-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  // box-shadow: 10px 10px 32px 0px #0000001a;
  box-shadow: 0px 1.95px 11.72px 0px #14142b14;
  background: #ffffff;
  padding: 20px;

  &:hover {
    border: 1px solid #bee9f0;
    background-color: #036185;
    color: #bee9f0;

    .pricing-card-price {
      color: #ffffff !important;
    }

    svg g path {
      fill: #ffffff;
      stroke: #ca9688;
    }
  }

  @media screen and (max-width: 990px) {
    max-width: 360px;
  }

  @media screen and (min-width: 990px) and (max-width: 1200px) {
    max-width: 400px;
  }

  .work-card-img {
    width: 70px;
    height: 70px;
    gap: 0px;
    background: #f1fbff !important;
    padding: 15px;
    border-radius: 8px;
  }
  .pricing-card-main-subtitle {
    min-height: 63px;
  }
  .pricing-card-subtitle {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    color: #425262;
  }
  .pricing-card-price {
    font-family: Poppins;
    font-size: 34px;
    font-weight: 700;
    color: #0d333f;
  }
  .pricing-card-Btn {
    background: #036185;
    color: #fff;
    padding: 20px;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    border-radius: 10px;
  }
}
.pricing-card:hover .pricing-card-price,
.pricing-card:hover .included,
.pricing-card:hover .pricing-card-title {
  color: #fff;
}

.pricing-card:hover .pricing-card-subtitle,
.pricing-card:hover .pricing-card-price,
.pricing-card:hover .pricing-card-title {
  color: #bee9f0;
}

.pricing-card:hover .pricing-card-Btn {
  background-color: white;
  color: #036185;
}

.selected-plan {
  border: 2px solid #036185;
}

.Otp-verification .MuiBox-root {
  justify-content: center;
}

.login-wrapper-subscription {
  background-color: var(--global-font-title);
  height: 100vh;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1200px) {
    height: auto;
  }

  .logo-wrap {
    img {
      max-width: 250px;
      margin: 0 auto;
    }
  }

  form {
    border-radius: var(--border-radius-10);

    .remeberme-check {
      .MuiCheckbox-root {
        &.Mui-checked {
          color: #273167;
        }
      }
    }

    .forgotPWD {
      color: var(--color-link);
    }
  }
}

@media screen and (max-width: 601px) {
  .media-steps {
    flex-direction: column !important;
  }
  .step-lable {
    padding: 10px 20px !important;
  }

  .pricing-card-price {
    font-size: 30px !important;
  }
  .pricing-card-Btn {
    padding: 15px !important;
    font-size: 16px !important;
  }

  .pricing-card {
    svg {
      height: 20px !important;
      width: 20px !important;
    }
  }

  .login-wrapper-subscription,
  .main-div-assistant {
    padding: 18px !important;

    .MuiStepper-root {
      flex-direction: column;

      .MuiStepLabel-root {
        text-align: center;
      }
    }
  }

  .monthly_plan,
  .annual_plan {
    font-size: 16px;
    padding: 0 15px;
  }

  .sub-box .MuiBox-root {
    padding: 10px 0;
    flex-direction: column;
  }
  .sub-tab {
    margin: auto !important;
    width: 100% !important;
  }

  .pricing-card-price {
    font-size: 24px !important;
  }

  .pricing-card {
    padding: 15px !important;
  }

  .pricing-card-subtitle {
    font-size: 13px !important;
  }

  .sub-button {
    display: flex;
    flex-direction: column !important;
    button {
      margin: 10px 0 !important;
    }
  }

  .login-wrapper-subscription {
    background-color: var(--global-font-title);
    height: 100vh;
    display: flex;
    align-items: center;
    height: auto;
    padding: 20px;
    form {
      box-shadow: none;
    }
  }
}
