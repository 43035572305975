.search-scroll {
  -webkit-scrollbar {
    width: 1em;
  }

  -webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(253, 0, 0, 0.3);
  }

  -webkit-scrollbar-thumb {
    background-color: rgb(255, 0, 0);
    outline: 1px solid rgb(0, 128, 255);
  }
}

.chat-main-box {
  padding: 0px 0px 8px !important;
  margin: 24px 0 8px 0 !important;
  max-height: calc(100vh - 150px) !important;
}

.hide-box {
  display: none !important;
}

.chat-main-box-paper {
  margin-top: 24px !important;
  padding: 20px;
  background: white;
  border: 1px solid #0d333f54;
  border-radius: 10px;
}

.chat-box-container {
  height: calc(100vh - 345px) !important;
  overflow-y: auto !important;
  margin-bottom: 10px !important;
}

.chat-box-input-container {
  margin-top: 8px !important;
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  width: auto !important;
  border-radius: 10px !important;
  border: 1px solid #0d333f54;
  background: #f6f6f6 !important;
}

.chat-box-container-full {
  overflow-y: auto !important;
  margin-bottom: 10px !important;
  height: calc(100vh - 280px) !important;
  padding-right: 5px;
}

.chat-input {
  margin-left: 8px !important;
  margin-left: auto;
  margin-right: auto;
  flex: 1 1 0% !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.close-preview {
  padding: 10px;
  font-size: 10px;
  float: right;
  cursor: pointer;
}

.document-listing-wrapper {
  width: 100%;
  height: calc(100vh - 180px);
  overflow: auto;
  padding: 0 10px 15px 0;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
  }
}

.document-chat-wrapper {
  height: calc(100vh - 180px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }
  .chat-main-box {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
  }
}

.copy-button {
  cursor: pointer;
  color: #71717a;
  &:hover {
    color: #000;
  }
}
