.login-wrapper {
    background-color: var(--global-font-title);
    height: 100vh;
    display: flex;
    align-items: center;

    .logo-wrap {
        img {
            max-width: 250px;
            margin: 0 auto;
        }
    }

    form {
        border-radius: var(--border-radius-10);
        box-shadow: 0px 0px 20px 13px rgba(255, 115, 100, 0.12);

        .remeberme-check {
            .MuiCheckbox-root {
                &.Mui-checked {
                    color: #273167;
                }
            }
        }

        .forgotPWD {
            color: var(--color-link);
        }
    }
}

.form-error-message {
    color: #f00;
    font-size: 12px;
    font-weight: 600;
}                           