.workflow-schedule {
  color: #777777;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  padding-top: "8px";
}

.outer-circle {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 2px solid #0a758f;
  border-radius: 50%;
}

.inner-circle {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #0a758f;
}

.circle-line-mian-div {
  position: absolute;
  left: 0;
  top: 0;
  bottom: -16px;
  display: flex;
  width: 24px;
  justify-content: center;
}

.line-div {
  width: 5px;
  background-color: #036185;
  position: relative;
  top: 4px;
}

.title-result-workflow {
  font-weight: 500 !important;
  margin-right: 10px !important;
  color: #323232;
}

.main-accordian {
  border: 1px solid #c1c1c1;
  border-radius: 8px !important;
  margin-top: 0px !important;
  width: 100%;

  .main-accordian-summury {
    margin: 0px !important;
    background-color: #f2f2f2 !important;
    border-radius: 8px 8px 0px 0 !important;
  }
}

.child-accordion {
  background-color: #f2f2f2 !important;
  border: 1px solid #c1c1c1 !important;
  border-radius: 8px !important;
  margin: 0px 10px 10px !important;
}

.child-accordion-details {
  padding: 10px !important;
  border-top: 1px solid #c1c1c1;

  .child-accordion-data {
    padding: 10px;
    border: 1px solid #c1c1c1;
    position: relative;
    border-radius: 8px;
  }

  .copy-clipboard-button {
    position: absolute;
    right: 0px;
    top: 0px;
    background: black;
    border-radius: 0 4px 0 8px;
  }
}

.elevation-response {
  padding: 10px !important;
  border: 1px solid #c1c1c1;
}
