.other-info-div {
  border: 2px solid skyblue;
  border-radius: 8px;
  padding: 15px;
  width: fit-content;

  .other-info-list {
    display: flex;
    align-items: center;
  }
}

.date-range-picker {
  width: fit-content;
  margin: 0 0px;
}

.datepicker-form-control {
  height: 100%;
}

.date-picker-container.machine-date {
  right: 25px;
}

.date-picker-container {
  display: flex;
  gap: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 10px 15px;
  margin-top: 2px;
  // position: absolute;
  z-index: 99;

  .react-datepicker {
    box-shadow: none !important;
    border: none;

    .react-datepicker__navigation {
      background-color: #64bcd2;
      border-radius: 8px;

      .react-datepicker__navigation-icon {
        top: 0px;
        &::before {
          // content: "";
          // border-color: #ccc;
          border-style: solid;
          border-width: 3px 3px 0 0;
          content: "";
          display: block;
          height: 9px;
          position: absolute;
          top: 6px;
          width: 9px;
          border-color: white !important;
        }
      }

      .react-datepicker__navigation-icon--previous {
        right: 0px;
        &::before {
          transform: rotate(225deg);
          right: -7px;
        }
      }
    }

    .react-datepicker__month-container {
      .react-datepicker__header {
        background-color: #ffffff;
        border-bottom: none;

        .react-datepicker__current-month {
          color: rgb(116, 98, 98);
        }

        .react-datepicker__day-names {
          padding-top: 15px;

          .react-datepicker__day-name {
            color: rgb(175, 158, 158);
          }
        }
      }

      .react-datepicker__month {
        .react-datepicker__day--selected {
          background-color: #64bcd2;
        }

        .react-datepicker__day--in-range {
          background-color: #64bcd2;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .date-picker-container {
    flex-direction: column;
  }
}

// / Preset options styling /
.preset-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: auto;
}

//  Style for individual date buttons
.preset-options .date-btn {
  font-size: 14px !important;
  font-weight: 400 !important;
  height: 30px !important;
  color: rgb(var(--primary-text-color)) !important;
  width: 100%;
}

//  Style for the apply button
.preset-options {
  .date-btn {
    border: 2px solid #64bcd2 !important;
    border-radius: 8px !important;
    padding: 10px 0 !important;
  }
  .apply-btn {
    background-color: #64bcd2;
    color: #ffffff;
    margin-top: auto;
    height: 30px;
    padding: 10px 8px 8px !important;
    width: 78px;

    &:hover {
      background-color: #64bcd2;
      color: #ffffff;
    }
  }

  .cancel-btn {
    background-color: rgb(63, 59, 59);
    color: #ffffff;
    margin-top: auto;
    height: 30px;
    padding: 10px 8px 8px !important;
    width: 78px;

    &:hover {
      background-color: rgb(63, 59, 59);
      color: #ffffff;
    }
  }
}
.accordian-btns {
  display: flex;
  justify-content: space-around;
}
.form-selection {
  .Mui-expanded {
    margin: 0px !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
