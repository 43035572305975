.assistant-dropdown {
  position: absolute !important;
  top: 22px !important;
  left: -44px !important;
}

.toggle-assistant {
  color: grey !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.toggle-assistant:hover {
  background-color: transparent !important;
}

.main-div-assistant {
  border: 1px solid #0d333f54;
  max-width: 72rem;
  padding: 2.5rem;
  background-color: white;
  border-radius: 10px;
  .MuiStepper-root {
    @media screen and (max-width: "900px") {
      flex-direction: column !important;
    }
  }
}

.margin-bottom-input {
  margin-bottom: 20px !important;
}

// .assistant-instruction > div > fieldset{
//     border: none  !important;
// }

.assistant-instruction > .MuiInputBase-root > input {
  height: 12px !important;
}

.step-circle > span > svg {
  color: var(--color-theme-primary) !important;
}

.active-step .MuiStepLabel-label {
  color: #fff !important;
}
.inactive-text .MuiStepLabel-label {
  color: #8a8a8a !important;
  font-weight: 400;
}

.New-Chat {
  color: black;
  background-color: transparent;
  box-shadow: none;
}

.New-Chat:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}

.step-lable .MuiStepLabel-iconContainer,
.MuiStepConnector-root {
  display: none !important;
}

.step-lable {
  border-radius: 400px;
  background-color: #f3f6f9;
  padding: 10px 60px !important;
  position: relative;

  @media screen and (max-width: "600px") {
    padding: 10px 40px !important;
  }
  @media screen and (min-width: "600px") and (max-width: 990px) {
    padding: 10px 45px !important;
  }
}

.step-lable > span {
  margin: 0px 40px !important;
}

.disablestep {
  color: #fff !important;
  opacity: 0.7;
}

.assistant-instructions > .MuiInputBase-root > input {
  height: 8px !important;
}

.KbTreeView {
  border: 1px solid rgb(208, 204, 204);
  border-radius: 5px;
  margin: 10px 0;
}

.all-files {
  overflow: auto;
  min-height: 150px;
}

.all-files::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

.all-files::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}

.disable-kb-select {
  opacity: 0.4;
  pointer-events: none;
}

.custom-active {
  border: 1px solid #5f6fc7 !important;
}

.assistantCard-hover {
  box-shadow: 0px 2px 27px 0px #0000001f !important;
  background-color: #d3c6c6;
  border-radius: 10px;

  @media screen and (min-width: 900px) and (max-width: 970px) {
    min-height: 210px;
    height: 100%;
  }
}

.assistantCard-hover:hover {
  border: 1px solid #000;
}

.edit-name-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #e5e7eb;
  padding: 4px 10px;
  border-radius: 0.25rem !important;
}

.chat-input-assistant > input {
  -webkit-text-fill-color: #adb5bd !important;
}

.chat-input-assistant {
  flex: 1 1 !important;
  padding: 15px 1.25rem;
  margin: 0px;
  padding: 10px;
  text-transform: capitalize;
}

.Market-button {
  color: black !important;
  border-radius: 20px !important;
  border: 1px solid #e5e7eb !important;
  background: white !important;
  font-size: 14px !important;
}

.recruitment-assistant {
  color: black !important;
  border-radius: 20px !important;
  border: 1px solid #e5e7eb !important;
  background: white !important;
  font-size: 14px !important;
  padding: 10px 20px !important;
  margin: 15px;
}

.header-chat-assistant {
  text-transform: capitalize;
}

.des-chat-assistant {
  font-size: 14px !important;
  color: grey !important;
  text-transform: capitalize;
}
.chat-loader-wrapper {
  position: relative;
  min-width: 65px;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 1px;
  min-width: 1px;
  display: flex;
}

.loader {
  --main-color: #babdc2;
  --highlight-color: #ced3ff;
  --bullet: 50%; /* 50% for round or 0% for square*/
  --size: 5px; /* 5px is standard size */
}

.loader span {
  float: left;
  margin: 0 calc(var(--size) / 1.4);
  width: var(--size);
  height: var(--size);
  transition: transform 0.2s ease-in-out, background 0.2s ease;
  -webkit-transition: transform 0.2s ease-in-out, background 0.2s ease;
  -moz-transition: transform 0.2s ease-in-out, background 0.2s ease;
  animation-iteration-count: infinite;
  animation-name: highlight;
  animation-duration: 1.2s;
  border-radius: var(--bullet);
  background: var(--main-color);
}

.loader span:nth-of-type(1) {
  animation-delay: 0s;
}

.loader span:nth-of-type(2) {
  animation-delay: 0.1s;
}

.loader span:nth-of-type(3) {
  animation-delay: 0.2s;
}

.loader span:nth-of-type(4) {
  animation-delay: 0.3s;
}

@keyframes highlight {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(2.1, 2.1);
    background: var(--highlight-color);
  }
  100% {
    transform: scale(1, 1);
  }
}

.disable-input-opacity {
  opacity: 0.5 !important;
}

.disable-input > textarea {
  cursor: not-allowed;
}

.disable-input {
  cursor: not-allowed !important;
}

.chat-box-input-assitant {
  margin-top: 8px !important;
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  width: auto !important;
  border-radius: 10px !important;
  border: 1px solid lightgray;
}

.edit-chat-modal {
  width: 500px;
  @media screen and (max-width: "600px") {
    width: 300px;
  }
}

.MuiButtonBase-root {
  &.MuiIconButton-root {
    &.assitant-send-btn {
      background-color: #fff;
      border-radius: 0 8px 8px 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.chat-box-container-assitant {
  height: calc(100vh - 329px) !important;
  overflow-y: auto !important;
  margin-bottom: 10px !important;
}
.scroll-bar-assitant > textarea {
  overflow-y: scroll;
  transition: all 0.5s !important;
  -webkit-transition: all 0.25s !important;
  border-right: none;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babdc2;
  }
}

.assistant-chat-title {
  display: flex;
  align-items: center;
}

.assistant-chat-title div {
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

// .assistant-chat-title div:hover {
//   background-color: #e5e7eb;
//   padding: 5px;
//   border-radius: 3px;
// }

.KB_Tab {
  color: var(--color-theme-secondary) !important;
}

.active_tab span {
  color: var(--color-theme-secondary) !important;
}

.assistant-chat-select {
  width: 100% !important;
}

@media only screen and (max-width: 580px) {
  .assistant-chat-title {
    padding-left: 0 !important;
  }
  .media-assitant {
    flex-direction: column;

    .MuiTypography-root {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  .default-assitant-media {
    height: 500px;
    width: 300px !important;
    overflow: scroll;
  }

  .Create-assitant-media {
    flex-direction: column;

    button {
      margin: 10px;
    }
  }
}

@media only screen and (min-width: 430px) and (max-width: 600px) {
  .default-assitant-media {
    height: 500px;
    width: 400px !important;
    overflow: scroll;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .default-assitant-media {
    width: 550px !important;
  }

  .Create-assitant-media {
    flex-direction: column;

    button {
      margin: 10px;
    }
  }
}
// @media only screen and (max-width: 1000px) {
//   .default-assitant-media {
//     height: 500px;
//     width: 400px;
//     overflow: scroll;
//   }
// }
