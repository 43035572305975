@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.d-done {
  display: none;
}
.publicNavWrapper {
  display: flex;
  box-shadow: 0px 34px 57.8px 0px #00000014;
  width: 100%;
  height: auto;
  // height: 170px;
  gap: 0px;
  border-radius: 0px 0px 30px 30px;
  background: #ffffff;
  position: fixed;
  z-index: 1100;
  top: 0;
  .appbar {
    height: auto;
    position: static;
    gap: 20px;
    padding: 0 0 15px 0;

    // .contact-details {
    //   display: none;
    // }

    .top-nav-toolbar {
      display: flex;
      padding: 0;
      gap: 0px;
      justify-content: space-between;

      // .Contact:hover & > .contact-details {
      //   display: block; /* Show contact details when .Contact is hovered */
      // }

      .main-nav-logo {
        // display: block;
        img {
          // width: 194px;
          width: 74px;
          height: 76px;
          @media screen and (min-width: 900px) and (max-width: 1100px) {
            width: 60px;
            height: 64px;
          }
        }
        @media screen and (max-width: 600px) {
          display: none !important;
        }
      }

      .nav-links {
        display: flex !important;
        width: auto;
        gap: 42px;
        justify-content: space-between;
        flex-direction: row;

        @media screen and (max-width: 900px) {
          gap: 20px;
        }

        a {
          p {
            font-size: 18px;
            color: #0d333f;
            font-weight: 500;
            line-height: 18px;
          }
        }

        button {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.01em;
          text-align: center;
          color: #0d333f;
          width: 54px;
          height: 18px;

          text-transform: none;
          gap: 0px;
        }
        @media screen and (max-width: 900px) {
          display: none !important;
        }
      }
      .nav-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        gap: 45px;

        .login-button {
          width: 48px;
          height: 22px;
          gap: 0px;

          font-family: Poppins;
          font-size: 18px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.01em;
          text-align: center;
          padding: 0;
          box-shadow: none;
          text-transform: none;
          text-decoration: underline;
          background: radial-gradient(
              93.49% 128.28% at 87.57% 100%,
              #64bcd2 0%,
              #036185 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .getting-start-button {
          width: 163px;
          padding: 16px 25px 16px 25px;
          gap: 10px;
          border-radius: 12px;

          font-family: Poppins;
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.01em;
          text-align: center;
          background: radial-gradient(
            100.93% 138.49% at -0.26% 7.52%,
            #64bcd2 0%,
            #036185 100%
          );
          border: none;
          text-transform: none;
          color: #fff;
        }
      }

      @media screen and (max-width: 602px) {
        .nav-buttons {
          display: flex;
          justify-content: end !important;
          flex-direction: row;
          width: 256px;
          gap: 12px;
        }
      }
    }
    .divider {
      height: 1px;
      background-color: #0361852b;
      margin: 0 0 10px 0;
    }
    .bottom-nav-toolbar {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      padding: 0px;
      .phone-talk,
      .sms-talk {
        display: flex;
        align-items: center;
        gap: 8px;
        img {
          width: 32px;
          height: 32px;
        }
        .text-content {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0.06em;
          text-align: left;
          color: #036185;
        }
      }
    }
  }
  @media screen and (min-width: 961px) and (max-width: 1280px) {
    height: auto;
    .appbar {
      height: auto;
      gap: 16px;
      .top-nav-toolbar {
        .main-nav-logo {
          img {
            width: 72px;
            height: 76px;
          }
        }
        .nav-links {
          gap: 24px;
        }
        .nav-buttons {
          gap: 25px;
          .getting-start-button {
            padding: 12px 18px 12px 18px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 601px) {
    .appbar {
      border-radius: 20px;
      .login-button {
        font-size: 16px !important;
      }
      .getting-start-button {
        padding: 10px !important;
        width: 120px !important;
        font-size: 16px !important;
      }
    }
  }
}

.landing-page-wrapper {
  .hero-section-wrapper {
    background: url("../assets/landing_page/hero-section-bg.png") top right
      no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    padding-top: 126px;
    padding-bottom: 50px;
    position: relative;

    @media screen and (min-width: 600px) and (max-width: 1280px) {
      padding-top: 50px;
    }

    .main-heading {
      font-family: Poppins;
      font-size: 44px;
      font-weight: 700;
      line-height: 52.8px;
      text-align: left;
      margin-bottom: 16px;
      background: radial-gradient(
          93.49% 128.28% at 87.57% 100%,
          #64bcd2 0%,
          #036185 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .sub-heading {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      margin-bottom: 32px;
      color: #000000;
    }
    .book-demo-btn {
      width: 182px;
      height: 52px;
      padding: 16px 25px 16px 25px;
      gap: 10px;
      border-radius: 12px;
      background: radial-gradient(
        100.93% 138.49% at -0.26% 7.52%,
        #64bcd2 0%,
        #036185 100%
      );
      border: none;
      text-transform: none;
      color: #fff;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: center;
    }
    .android-ios-btn {
      background: #0000;
      border-radius: 12px;
      height: auto;
      min-width: auto;
      overflow: hidden;
      padding: 0;
      width: auto;
    }
    @media screen and (min-width: 961px) and (max-width: 1280px) {
      .main-heading {
        font-size: 35px;
        line-height: 48px;
      }
      .sub-heading {
        font-size: 18px;
        line-height: 26px;
      }
      .book-demo-btn {
        width: 182px;
        padding: 12px 18px 12px 18px;
      }
    }
    @media screen and (min-width: 601px) and (max-width: 960px) {
      .main-heading {
        font-size: 30px;
        line-height: 48px;
      }
      .sub-heading {
        font-size: 18px;
        line-height: 26px;
      }
      .book-demo-btn {
        width: 182px;
        padding: 12px 18px 12px 18px;
      }
      .HeroBanner {
        max-width: 400px;
        margin: auto;
      }
    }
    @media screen and (max-width: 601px) {
      .main-heading {
        font-size: 30px;
        line-height: 48px;
      }
    }
  }
}

.how-it-works-section {
  margin-top: 78px;
  .how-it-works-title {
    margin-bottom: 40px;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 700;
    line-height: 63px;
    text-align: center;
    color: #0d333f;

    @media screen and (min-width: 600px) and (max-width: 1280px) {
      font-size: 34px;
    }
  }
  .how-it-works-section-grid {
    display: flex;
    justify-content: center;
    .work-card {
      width: 100%;
      border-radius: 24px;
      box-shadow: 10px 10px 32px 0px #0000001a;
      background: #ffffff;
      padding: 24px;

      @media screen and (max-width: 601px) {
        text-align: center;
      }
      .work-card-img {
        width: 52px;
        height: 52px;
        gap: 0px;
        margin-bottom: 24px;
      }
      .work-card-title {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #4a4a4a;

        @media screen and (max-width: 601px) {
          text-align: center;
        }
      }
    }
  }
}

.application-industries-img {
  margin-top: 120px;
  display: flex;
  justify-content: center;
  img {
    max-height: 500px;
    height: 100%;
  }
}
@media screen and (min-width: 961px) and (max-width: 1280px) {
  .application-industries-img {
    margin-top: 140px;
  }
}
.application-areas-section {
  margin-top: 78px;
  position: relative;

  .application-areas-layers {
    position: absolute;
    // top: -34px;
    width: 100%;
    height: 130px;
    img {
      width: 100%;
      height: 130px;

      @media screen and (max-width: 600px) {
        height: 77px;
      }
    }
  }
  .application-areas-title {
    margin-bottom: 40px;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    color: #0d333f;
    // height: 63px;

    @media screen and (min-width: 600px) and (max-width: 1280px) {
      font-size: 34px;
    }
  }
  .application-section {
    display: flex;
    justify-content: space-between;
    margin-top: 105px;
    width: 100%;
    gap: 16px;
    background: url("../assets/landing_page/applicationIllustrationNew.png") top
      right no-repeat;
    background-repeat: no-repeat;
    background-size: 450px 336px;
    // height: 356px;
    max-height: 100%;
    background-position: center;
    @media screen and (max-width: 600px) {
      margin-top: 65px;
    }
    .application-card-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      @media screen and (min-width: 600px) and (max-width: 1280px) {
        flex-direction: column;
      }

      .application-work-card {
        max-width: 384px;
        width: 100%;
        // height: 95px;
        padding: 16px 24px 16px 24px;
        gap: 12px;
        border-radius: 24px;
        box-shadow: 10px 10px 32px 0px #0000001a;
        background: #ffffff;
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;

        @media screen and (min-width: 600px) and (max-width: 1280px) {
          max-width: 100%;
        }

        .application-card-img {
          width: 54px;
          height: 58px;
        }
        .application-card-title {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;

          background: radial-gradient(
            100.93% 138.49% at -0.26% 7.52%,
            #64bcd2 0%,
            #036185 100%
          );

          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .application-card-sub-title {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
          text-align: left;
          color: #0d333f;
        }
        @media only screen and (max-width: 600px) {
          .application-card-title {
            font-size: 16px;
          }
        }
      }
    }

    .application-card-wrapper-2 {
      align-items: flex-end;
      @media screen and (max-width: 600px) {
        align-items: flex-start;
      }
    }
  }

  @media screen and (min-width: 961px) and (max-width: 1280px) {
    .application-section {
      gap: 180px;
    }
  }
  @media only screen and (max-width: 600px) {
    .application-section {
      gap: 25px;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }

    .application-work-card {
      width: 100% !important;
      max-width: 100% !important;
    }

    .application-areas-layers {
      top: 0px;
      // right: -13px;
    }
  }
}
.application-areas-section-background::before {
  background-repeat: no-repeat;
  position: absolute;
  content: url(../assets/landing_page/applicable_areas.png);
  right: 0;
  bottom: -180px;
  z-index: -1;
}

@media only screen and (max-width: 900px) {
  .book-demo-data {
    height: auto !important;
    .MuiGrid-container {
      flex-direction: column-reverse;
      padding: 20px;
    }
    .book-demo-text {
      padding: 0 20px !important;
      .book-demo-title {
        font-size: 30px !important;
        margin-bottom: 5px !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .hero-section-wrapper {
    padding-top: 30px !important;
  }

  .application-areas-section-background::before {
    content: "" !important;
  }

  .application-areas-section-applicable::before {
    content: "" !important;
  }

  .application-industries-img,
  .book-demo-section {
    margin-top: 20px !important;
  }

  .application-industries-img > img {
    height: 250px;
  }

  .book-demo-text {
    padding: 10px 0px 23px !important;
  }

  .book-demo-data {
    height: auto !important;
    .MuiGrid-container {
      flex-direction: column-reverse;
      padding: 20px;
    }
  }

  .book-demo-title,
  .application-areas-title,
  .how-it-works-title {
    font-size: 30px !important;
    margin-bottom: 0px !important;
  }

  .work-card {
    width: 100% !important;
  }
}
.application-areas-section-applicable {
  position: relative;
  padding: 40px 0px;
  @media screen and (max-width: 600px) {
    padding: 50px 0px 60px;
  }
}

.application-areas-section-applicable::before {
  background-repeat: no-repeat;
  position: absolute;
  content: url(../assets/landing_page/Applicable.png);
  left: 0;
  top: -150px;
  z-index: -1;
}

.book-demo-section {
  margin-top: 152px;
  .book-demo-data {
    // height: 320px;
    border-radius: 30px;
    background: #64bcd2;
    width: 100%;
    position: relative;
    .book-demo-text {
      padding: 32px 102px;
      .book-demo-title {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 700;
        line-height: 63px;
        text-align: left;
        color: #fff;
        margin-bottom: 16px;
      }
      .book-demo-subtitle-wrapper {
        .book-demo-subtitle {
          margin-bottom: 8px;
          img {
            width: 20px;
            height: 20px;
          }
          span {
            margin-left: 8px;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 27px;
            text-align: left;
            color: #fff;
            text-transform: capitalize;
          }
        }
      }
      .book-demo-btn {
        height: 52px;
        padding: 16px 25px 16px 25px;
        gap: 10px;
        border-radius: 12px;

        background: #036185;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-align: center;
        text-transform: none;
        margin-top: 16px;
        color: #fff;
      }
    }
  }
  .book-demo-img {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-top: -45px;
      margin-right: 104px;
    }
  }

  @media screen and (max-width: 991px) {
    .book-demo-img {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-top: -65px;
        margin-right: 0px;
      }
    }
  }

  @media screen and (min-width: 961px) and (max-width: 1280px) {
    margin-top: 102px;
    .book-demo-data {
      .book-demo-text {
        padding: 28px 50px;
        .book-demo-btn {
          padding: 12px 18px 12px 18px;
        }
      }
    }
  }
}

.bottom-nav-toolbar-sidebar {
  padding: 10px;
  .phone-talk-sidebar,
  .sms-talk-sidebar {
    display: flex;
    margin-bottom: 5px;
    span {
      width: 22px;
      height: 22px;
    }
    img {
      width: 22px;
      height: 22px;
    }
    .text-content {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0.06em;
      color: #036185;
      padding-left: 5px;
    }
  }
}
