@media (max-width: 500px) {
  .form300-selection action-btns {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start !important;
  }
  .history .action-btns {
    gap: 12px;
    align-items: flex-start !important;
  }
  .inner-action-btns {
    margin-left: auto !important;
  }
}
.table-disabled {
  pointer-events: none; /* Disable all mouse interactions */
}
.form-disabled {
  pointer-events: none; /* Disable all mouse interactions */
}
.error-msg {
  color: red;
  font-weight: bold;
  font-size: 14px;
  margin-top: 2px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.modal-date-picker {
  width: 100%;
  border-color: var(--border);
  border-radius: 10px;
  border-left-color: red;
}

.modal-date-picker.rs-picker-disabled {
  opacity: 1;
  background: hsla(0, 17%, 94%, 0.747) !important;
}

.modal-date-picker.rs-picker-disabled .rs-picker-toggle.rs-btn {
  background-color: hsla(0, 17%, 94%, 0.747) !important;
  border: 1px solid rgba(0, 0, 0, 0.26);
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMultiSectionDigitalClockSection-item {
  background-color: var(--color-theme-header);
}

.form301-textarea {
  background-color: #f0f8fb;
}

.form301-textarea .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-theme-header); /* Default border color */
}

.form301-textarea
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-theme-header); /* Border color on hover */
}

.form301-textarea
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-theme-header); /* Border color when focused */
}

.form301-container .MuiAccordionSummary-root.Mui-expanded {
  background-color: var(--color-theme-header) !important;
  min-height: 56px !important;
  label {
    color: white !important;
  }
  svg {
    fill: white !important;
  }
}
.action-btn {
  align-items: center !important;
  border: none !important;
  border-radius: 10px !important;
  display: flex !important;
  height: 40px;
  justify-content: center !important;
  min-width: 40px !important;
  padding: 0 !important;
}
.action-btn.dlt-btn {
  background-color: #e6eff3;
}
.action-btn.dlt-btn span svg {
  fill: var(--color-theme-secondary) !important;
}
