@import url("./styles/variables.scss");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: var(--global-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--global-body-lightcolor);
  overflow-y: auto !important;
  padding-right: 0 !important;
}

/* body::-webkit-scrollbar {
  width: 0;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--border-color-dark);
} */

::-webkit-scrollbar {
  height: 8px; /* Set the height of the horizontal scrollbar */
  width: 8px; /* Set the width for the vertical scrollbar */
}

/* Styling the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray background for the track */
}

/* Styling the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #64bcd2; /* Blue color for the scrollbar */
  border-radius: 8px; /* Rounder edges */
}

/* Optional: Change the color of the scrollbar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #4a9cbf; /* Darker blue on hover */
}

img {
  max-width: 100%;
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0;
}

.section {
  padding: 60px 0;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
  min-height: 34px;
}

h1.MuiTypography-root {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-bold);
  color: var(--global-font-title);
}

h2.MuiTypography-root {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-bold);
  color: var(--global-font-title);
}

h3.MuiTypography-root {
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-semi-bold);
  color: var(--global-font-title);
}

h4.MuiTypography-root {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-semi-bold);
  color: var(--global-font-subtitle);
}

p.MuiTypography-root {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-regular);
  color: var(--global-font-subtitle);
}

.subtitle.MuiTypography-root {
  font-size: var(--font-size-body);
  font-weight: var(--font-weight-medium);
  color: var(--color-theme-secondary);
}

.primary-button,
.primary-button:hover,
.primary-button:focus,
.primary-button:visited,
.primary-button:active,
.primary-button:active:hover,
.primary-button:active:focus {
  background-color: var(--color-theme-secondary) !important;
  box-shadow: var(--shadow-none) !important;

  font-weight: var(--font-weight-semi-bold) !important;
  border-radius: 7px !important;
}

.secondary-button,
.secondary-button:hover,
.secondary-button:focus,
.secondary-button:visited,
.secondary-button:active,
.secondary-button:active:hover,
.secondary-button:active:focus {
  background-color: var(--global-body-bgcolor) !important;
  box-shadow: var(--shadow-none) !important;
  border-radius: var(--border-radius-50) !important;
  color: var(--color-link) !important;
  border-color: var(--color-link) !important;
}

.primary-button.add-btn,
.primary-button.add-btn:hover,
.primary-button.add-btn:focus,
.primary-button.add-btn:visited,
.primary-button.add-btn:active,
.primary-button.add-btn:active:hover,
.primary-button.add-btn:active:focus {
  background: var(--color-theme-secondary) !important;
  box-shadow: var(--shadow-none) !important;
  border-radius: 8px !important;
  font-weight: var(--font-weight-semi-bold) !important;
  height: 48px;
  font-size: 16px !important;
}

.primary-button.add-btn:disabled {
  color: var(--color-theme-white) !important;
  opacity: 0.7;
  cursor: not-allowed;
}

.back-button {
  border-radius: 7px !important;
  font-weight: var(--font-weight-semi-bold) !important;
  height: 44px;
}

.getStart-button {
  background-color: var(--color-theme-primary) !important;
  box-shadow: var(--shadow-none) !important;
  border-radius: var(--border-radius-50) !important;
  font-weight: var(--font-weight-bold) !important;
  font-size: var(--font-size-h6) !important;
  border: 2px solid var(--color-link) !important;
}

.getStart-button:hover,
.getStart-button:focus,
.getStart-button:visited,
.getStart-button:active,
.getStart-button:active:hover,
.getStart-button:active:focus {
  background-color: transparent !important;
  box-shadow: var(--shadow-none) !important;
  border-radius: var(--border-radius-50) !important;
  font-weight: var(--font-weight-bold) !important;
  font-size: var(--font-size-h6) !important;
  color: var(--color-theme-primary) !important;
  border: 2px solid var(--color-link) !important;
}

.subtitle_divider::before,
.subtitle_divider::after {
  border-top: thin solid var(--border-color-light) !important;
}

.MuiChip-filled.MuiChip-root {
  background-color: var(--color-theme-primary);
  font-size: var(--font-size-normal);
  color: #ffffff;
}

.MuiChip-filled.MuiChip-root .MuiChip-label {
  padding: 0 24px;
}

/* NAVBAR CSS:: START */

#HeaderNavbar .publicNav_logo img {
  max-width: 230px;
}

#HeaderNavbar {
  transition: all 0.5s ease;
  box-shadow: none;
  background-color: #ffffff !important;
}

.nav-items button,
.nav-items button:hover,
.nav-items button:focus,
.nav-items button:visited,
.nav-items button:active,
.nav-items button:active:horizontal,
.nav-items button:active:focus {
  background-color: transparent !important;
  color: var(--global-font-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-normal);
  line-height: var(--font-line-height-p);
  letter-spacing: var(--letter-spacing-one);
}

.nav-items li span,
.nav-items li span:hover,
.nav-items li span:focus,
.nav-items li span:visited,
.nav-items li span:active,
.nav-items li span:active:horizontal,
.nav-items li span:active:focus {
  background-color: transparent !important;
  color: var(--global-font-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-normal);
  line-height: var(--font-line-height-p);
  letter-spacing: var(--letter-spacing-one);
}
.nav-items li a p,
.nav-items li a p:hover,
.nav-items li a p:focus,
.nav-items li a p:visited,
.nav-items li a p:active,
.nav-items li a p:active:horizontal,
.nav-items li a p:active:focus {
  background-color: transparent !important;
  color: var(--global-font-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-normal);
  line-height: var(--font-line-height-p);
  letter-spacing: var(--letter-spacing-one);
}

/* NAVBAR CSS:: END */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #HeaderNavbar .publicNav_logo img {
    max-width: 170px;
  }
}

/* DASHBOARD */
header.dashboard-navbar {
  /* background-color: var(--color-theme-secondary); */
  background-color: transparent;
  /* position: -webkit-sticky !important; */

  position: relative !important;
  width: auto;
  padding: 10px 0;

  z-index: 1200 !important;
  box-shadow: var(--shadow-none);
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
  border-bottom: 1px solid rgba(39, 49, 103, 0.1);

  position: sticky !important; /*jk*/
  background-color: var(--global-body-lightcolor); /*jk*/
  margin-left: 260px; /*jk*/
  transition: margin-left 0.5s ease;
}

@media only screen and (max-width: 600px) {
  header.dashboard-navbar {
    margin-left: 0px !important;
  }
}

/* .sidebar-open {
  margin-left: 130px !important;
} */

/* header.dashboard-navbar.sidebar-closed {
  margin-left: 80px;
} */

.small-sidebar-width {
  width: 100px !important;
}
.large-sidebar-width {
  width: 240px !important;
}
.small-sidebar-margin {
  margin-left: 100px !important;
}
.large-sidebar-margin {
  margin-left: 240px !important;
}

header.dashboard-navbar > div {
  padding: 0;
}

.MuiDataGrid-cell--withRenderer.MuiDataGrid-withBorderColor {
  outline: 0 none !important;
}

.configure-action-tab .MuiButtonBase-root.Mui-selected {
  font-weight: bold;
  color: var(--color-theme-secondary) !important;
}

.configure-action-tab .MuiTabs-indicator {
  background-color: var(--color-theme-secondary) !important;
}

.add-action-btn.Mui-disabled {
  color: #ffffff !important;
  opacity: 0.5;
  cursor: not-allowed !important;
}

.comingSoon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  min-height: 90vh;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.end {
  display: flex;
  justify-content: end;
  align-items: center;
}

.start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.ql-editor {
  height: 300px !important;
}
.privacy-policy {
  padding: 20px 0px;
}
.privacy-policy h1,
.privacy-policy h2 {
  color: #333;
  font-weight: bold;
}
.privacy-policy ul {
  margin: 10px 15px;
}
.privacy-policy a:link,
.privacy-policy a:visited {
  color: #0077cc;
  text-decoration: none;
}
.privacy-policy a:hover {
  text-decoration: underline;
}

.modal-scroll .MuiBackdrop-root {
  z-index: 99;
}
.modal-scroll .MuiDialog-scrollPaper {
  position: relative;
  z-index: 999;
}

.modal-scroll .MuiDialog-container .MuiPaper-root {
  z-index: 9999;
}

.project-location-autocomplete {
  .MuiAutocomplete-inputRoot {
    padding: 8px !important;
  }
}

.form-other-information-cell {
  border-bottom: 0px !important;
}

.table-other-info {
  margin-bottom: 15px !important;
}

.table-other-info .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
  background-color: #efefef !important;
  color: black !important;
}

.table-report-data-cell {
  border-bottom: 1px solid #0d333f54 !important;
}

.other-info-list-title {
  font-weight: 500 !important;
  width: 60% !important;
  font-size: 0.95rem !important;
}

.other-info-list-description {
  font-size: 0.95rem !important;
}

.other-info-list-divider {
  margin: 0.6rem 0 !important;
}

.dashboard-card .MuiCardContent-root {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  transition: border ease-in 200ms;
  cursor: pointer;
}

.dashboard-card:hover {
  border: 1px solid var(--color-theme-secondary);
  box-shadow: 0px !important;
}

.dashboard-card-title {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
}

.dashboard-card-count {
  margin-top: 7px !important;
}

.graph-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.dashboard-selection .MuiSelect-select {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.graph-main-div {
  display: flex;
  width: 100%;
  margin-top: 40px;
  gap: 20px;
}

.graph-filter-div {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.dashboard-graph-div {
  flex: 1;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

@media screen and (max-width: 601px) {
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
    min-height: 20px;
  }
  header.dashboard-navbar .MuiToolbar-root .MuiButtonBase-root {
    display: flex;
  }

  .modal-scroll .MuiDialog-container .MuiPaper-root .MuiTypography-root {
    font-size: 16px !important;
  }
}

.side-drawer-2 .MuiDrawer-paper {
  max-width: 500px;
  width: 100%;
}
.sidebar-tab-wrapper-2 {
  background-color: #64bcd2;
  color: white;
}

.side-drawer-2 .MuiDrawer-paper .sidebar-tab-wrapper-2 .sidebar-tab {
  border-bottom: 1px solid #ddd;
}

.side-drawer-2 .MuiDrawer-paper .sidebar-tab-wrapper-2 .close-btn {
  align-items: center;
  background-color: #0000;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: 14px;
  width: 20px;
  z-index: 99;
}
.side-drawer-2 .MuiDrawer-paper .sidebar-tab-wrapper-2 .close-btn svg {
  font-size: 20px;
}

.side-drawer-2
  .MuiDrawer-paper
  .sidebar-tab-wrapper-2
  .sidebar-tab
  .tab-label-btn {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-width: 120px;
  opacity: 1;
  text-transform: capitalize;
}
label {
  display: flex;
  font-size: 14px !important;
  font-weight: bold !important;
  color: var(--font-secondary-color) 1important;
}

.toggle-language-btn {
  height: 44px;
  border-radius: 7px !important;
}

.toggle-language-btn .nonSelected {
  background-color: white;
  color: var(--color-theme-secondary); /* Text color */
  border: 2px solid var(--color-theme-secondary);
}

/* Selected button */
.toggle-language-btn .selected {
  background-color: var(--color-theme-secondary) !important;
  color: white !important; /* Text color */
  border: 2px solid var(--color-theme-secondary) !important;
}

.MuiDataGrid-overlayWrapperInner {
  height: 88px !important;
}

.sticky-right {
  position: sticky !important;
  right: 0 !important;
  background-color: #fff;
  z-index: 100 !important;
  box-shadow: inset 3px 0 3px -3px rgba(0, 0, 0, 0.2);
}

.footer {
  position: fixed; /* Fixes the footer to the bottom */
  bottom: 0; /* Aligns it to the bottom of the viewport */
  left: 0; /* Aligns it to the left side */
  width: 100%; /* Full width */
  height: 80px; /* Fixed height */
  background-color: #ffffff; /* White background */
  display: flex; /* Enables flexbox */
  justify-content: flex-end; /* Aligns items to the end (right) */
  align-items: center; /* Centers items vertically */
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  padding: 0 24px; /* Horizontal padding */
  border-radius: 8px 8px 0 0; /* Rounded top corners */
  z-index: 0; /* Ensures it's above other content */
  gap: 15px;
}

.primary-footer-btn {
  padding: 8px 32px !important; /* Padding with important to override other styles */
  background-color: var(--color-theme-primary-btn) !important;
  box-shadow: var(--shadow-none) !important;
  border-radius: var(--border-radius) !important;
  font-weight: var(--font-weight-semi-bold) !important;
  color: var(--color-theme-white) !important;
  transition: background-color 0.3s, color 0.3s !important; /* Smooth transition for hover effects */
}

.primary-footer-btn:hover {
  /* background-color: #fff !important;
  color: var(
    --color-theme-secondary
  ) !important;
  border: 1px solid var(--color-theme-secondary) !important;  */

  background-color: var(--color-theme-primary-btn-hover) !important;
  color: var(--color-theme-white) !important;
  border-radius: var(--border-radius) !important;

  font-weight: var(--font-weight-semi-bold) !important;
}

.secondary-footer-btn {
  padding: 8px 32px !important; /* Padding with important to override other styles */
  border-radius: 5px !important; /* Rounded corners */
  background-color: #fff !important; /* White background */
  color: var(--color-theme-secondary) !important; /* Secondary color for text */
  border: 1px solid var(--color-theme-secondary) !important; /* Border with secondary color */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.secondary-footer-btn:hover {
  color: #fff !important; /* White text on hover */
  background-color: var(
    --color-theme-secondary
  ) !important; /* Change background color on hover */
  border: 1px solid var(--color-theme-secondary) !important; /* Maintain border on hover */
}

.box-container {
  background-color: #ffffff; /* White background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Box shadow for depth */

  padding: 24px; /* Inner padding */
  overflow-y: auto; /* Scrollable content if necessary */
}

.rich-editor {
  .ql-editor {
    height: 180px !important;
    ul {
      padding-left: 0;
    }
    ol {
      padding-left: 0;
    }
  }

  .ql-container {
    font-size: 14px !important;
  }
  .ql-container.ql-snow {
    border-radius: 4px;
  }
}
.view {
  .ql-editor {
    height: 100% !important;
  }
}
.rich-editor-text-view {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
